import React, { useState } from "react"
import Button from "../components/button"
import styles from "../styles/scss/components/gallery.module.scss"
import GalleryModal from "./galleryModal"
import Imgix from "react-imgix"

const Gallery = ({ title, ctaText, images }) => {
    // STATES
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)

    // HANDLERS
    const handlerOpenGallerySlick = index => {
        setCurrentIndex(index)
        setIsModalOpen(true)
    }
    const slideImages = images

    return (
        <div className={`${styles.galleryMainWrapper_default}`}>
            <div
                id={`gallery-${title}`}
                className={`${styles.galleryDefaultContentWrapper} `}
            >
                {
                    <>
                        <div className={`${styles.galleryMainWrapper_images}`}>
                            {slideImages.map((item, index) => (
                                <div
                                    key={index}
                                    aria-hidden
                                    role="button"
                                    onKeyDown={e => {
                                        e.preventDefault()
                                        handlerOpenGallerySlick(index)
                                    }}
                                    onClick={e => {
                                        e.preventDefault()
                                        handlerOpenGallerySlick(index)
                                    }}
                                >
                                    <Imgix
                                        className={styles.graphic}
                                        alt={`${item.alt}`}
                                        src={item.url}
                                        sizes="100vw"
                                        htmlAttributes={{alt: item.alt}}
                                        imgixParams={{ ar: "16:9" }}

                                    />
                                </div>
                            ))}
                        </div>
                        {ctaText && (
                            <Button
                                submit
                                className={styles.redButton}
                                text={ctaText}
                                onClick={e => {
                                    e.preventDefault()
                                    handlerOpenGallerySlick(1)
                                }}
                            />
                        )}
                    </>
                }

                {isModalOpen && (
                    <GalleryModal
                        images={slideImages}
                        isModalOpen={isModalOpen}
                        setCurrentIndex={setCurrentIndex}
                        currentIndex={currentIndex}
                        setIsModalOpen={setIsModalOpen}
                    />
                )}
            </div>
        </div>
    )
}

export default Gallery
