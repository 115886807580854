import { isBrowser } from "./util"
import DatoCmsSearch from "datocms-search/src/base"

export const doSearch = (query) => {
    return new Promise(function (resolve, reject) {
        if (!isBrowser()) {
            reject("Do not query search from Node!")
        } else {
            var client = new DatoCmsSearch(process.env.GATSBY_DATOCMS_SITESEARCH_TOKEN, "production")
            client.search(query, { limit: 50 }).then(function (response) {
                resolve(response)
            })
        }
    })
}