import React, { useState } from 'react'
import NewLayout from "../layout/newLayout"
import styles from '../styles/scss/pages/search.module.scss'
import PageContents from '../components/pageContents'
import SEO from "../components/seo"
import cn from "classnames"
import { doSearch } from "../services/search"
import { Formik } from "formik"
import { supSub } from "../services/util"
import { isArray as _isArray } from "lodash"

const Search = ({ ...props }) => {
    var pg = props.pageContext.page
    const [results, setResults] = useState('');
    const [total, setTotal] = useState('');
    const [submitted, setSubmited] = useState(false);

    return (
        <NewLayout>
            {pg.seo.title && <h1 style={{ display: 'none' }}>{pg.seo.title}</h1>}
            <SEO page={pg} />
            <div className={cn('container', styles.container)}>
                <PageContents content={pg.content} />

                <div>
                    <Formik
                        initialValues={{
                            query: ""
                        }}
                        validate={values => {
                            let errors = []
                            if (!values.query) {
                                errors.push({ field: "query", message: "A search term is required" })
                            }
                            return errors
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            doSearch(values.query).then(data => {
                                setResults(data.results);
                                setTotal(data.total);
                                setSubmited(true)
                                setSubmitting(false);
                            })
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form className={styles.form} onSubmit={handleSubmit}>
                                <h1>Search</h1>
                                <div className={styles.formWrapper}>

                                    <input
                                        type="text"
                                        name="query"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.query}
                                    />
                                    {errors.query && touched.query && errors.query}
                                    <button type="submit" disabled={isSubmitting}>
                                        Submit
                                    </button>
                                </div>

                                <div className={styles.feedback}>
                                    <ul>
                                        {_isArray(errors) && errors.filter((f) => { return touched[f.field] }).map((e) => {
                                            return <li key={e.field}>{e.message}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className={styles.resultsSummary}>
                                    {submitted && <>{total} Results</>}
                                </div>

                                <div className={styles.resultsContainer}>
                                    {results && results.map((r, i) => {
                                        return (
                                            <div key={r.url} className={styles.result}>
                                                <a href={r.url} dangerouslySetInnerHTML={{ __html: supSub(r.title) }}></a>
                                                <div dangerouslySetInnerHTML={{ __html: r.body }}></div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </NewLayout>
    )
}

export default Search;